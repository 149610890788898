
import { defineComponent, ref, onMounted } from "vue";
import Datatable from "@/components/kt-datatable/KTDatatable.vue";
import ExportCustomerModal from "@/components/modals/forms/ExportCustomerModal.vue";
// import AddCustomerModal from "@/components/modals/forms/AddCustomerModal.vue";
import { setCurrentPageBreadcrumbs } from "@/core/helpers/breadcrumb";
import reports from "@/core/data/report";
import { IReport } from "@/core/data/report";
import Swal from "sweetalert2/dist/sweetalert2.js";
// import DatePicker from "vue-datepicker-next";
import "vue-datepicker-next/index.css";
import { useRoute, useRouter } from "vue-router";
import { useStore } from "vuex";
import { Actions } from "@/store/enums/StoreEnums";
import { ErrorMessage, Field, Form } from "vee-validate";

export default defineComponent({
  name: "customers-listing",
  components: {
    Datatable,
    ExportCustomerModal,
    ErrorMessage,
    Field,
  },
  setup() {
    const store = useStore();
    const router = useRouter();
    const route = useRoute();
    const userData = ref([]);
    const userList = ref([]);
    const showTable = ref(false);
    const checkedReport = ref([]);
    const tableHeader = ref([
      {
        name: "User",
        key: "user",
        sortable: true,
      },
      {
        name: "My Number",
        key: "myNumber",
        sortable: true,
      },
      {
        name: "Number",
        key: "number",
        sortable: true,
      },
      {
        name: "Message",
        key: "message",
        sortable: true,
      },
      {
        name: "Created Date",
        key: "date",
        sortable: true,
      },
      {
        name: "Status",
        key: "status",
        sortable: true,
      },
    ]);

    const tableData = ref<Array<IReport>>([]);
    const initCustomers = ref<Array<IReport>>([]);

    onMounted(() => {
      getUser();
      setCurrentPageBreadcrumbs("Report", []);
      initCustomers.value.splice(0, tableData.value.length, ...tableData.value);
      getReportData();
    });

    const getUser = () => {
      var request = {
        url: `users`,
      };
      store.dispatch(Actions.GET, request).then((data) => {
        if (data) {
          // console.log('data user',data)
          userData.value = data.data;
        }
      });
    };

    const userselect = (e) => {
      console.log("fgd", e.target.value);
    };

    const getReportData = () => {
      var request = {
        url: `report-data`,
      };
      store.dispatch(Actions.GET, request).then((data) => {
        if (data) {
          tableData.value = data.data;
          initCustomers.value.splice(
            0,
            tableData.value.length,
            ...tableData.value
          );

          showTable.value = true;
          console.log("datata", tableData.value);
        }
      });
    };

    const deleteFewReport = () => {
      checkedReport.value.forEach((item) => {
        deleteReport(item);
      });
      checkedReport.value.length = 0;
    };

    const deleteReport = (id) => {
      Swal.fire({
        title: "Are you sure?",
        text: "You won't be able to revert this!",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Yes, delete it!",
      }).then((result) => {
        if (result.isConfirmed) {
          // for (let i = 0; i < tableData.value.length; i++) {
          //   if (tableData.value[i].id === id) {
          //     tableData.value.splice(i, 1);
          //   }
          // }
          Swal.fire("Deleted!", "Report has been deleted.", "success");
        }
      });
    };

    const search = ref<string>("");
    const searchItems = () => {
      tableData.value.splice(0, tableData.value.length, ...initCustomers.value);
      if (search.value !== "") {
        let results: Array<IReport> = [];
        for (let j = 0; j < tableData.value.length; j++) {
          if (searchingFunc(tableData.value[j], search.value)) {
            results.push(tableData.value[j]);
          }
        }
        tableData.value.splice(0, tableData.value.length, ...results);
      }
    };

    const searchingFunc = (obj, value): boolean => {
      for (let key in obj) {
        if (!Number.isInteger(obj[key]) && !(typeof obj[key] === "object")) {
          if (obj[key].indexOf(value) != -1) {
            return true;
          }
        }
      }
      return false;
    };

    return {
      tableData,
      tableHeader,
      deleteReport,
      search,
      searchItems,
      checkedReport,
      deleteFewReport,
      userData,
      getReportData,
      showTable,
      userList,
      userselect,
    };
  },
});
